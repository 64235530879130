// util.js has all the functions that store the utility functions that related
// to Redux.
import indexOf from 'lodash/indexOf';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import { createActions, handleActions } from 'redux-actions';
import 'jquery';
import update from 'immutability-helper';
import { doNothing, emptyObject } from "../common/constants";
import { DummyFunction } from "../common/v5/helpers";

export const asyncInitState = {wip: false, xhr: null, err: null, data: null};

export const periodicAsyncInitState = {wip: false, xhr: null, err: null, data: null, periodic: emptyObject};

const virginOpr = {opr: emptyObject, allSelected: false};

export const initWithOpr = {wip: false, xhr: null, err: null, data: virginOpr};

export const oprAsyncInitState = {wip: false, xhr: null, err: null, data: null, opr: emptyObject};

export const defCancelReducer = (st, act) =>
	update(st, {$merge: {wip: false, xhr: null, err: null, param: null}});

export const defRequestReducer = (st, act) => {
	if(!act.pure && st.xhr) {
		console.log('dbg: impure cancellation.');
		st.xhr.abort();
	}
	return update(st, {$merge: {wip: true, xhr: null, err: null,
		param: act.payload.param}});
};

export const defStartReducer = (st, act) =>
	update(st, {$merge: {xhr: act.payload.xhr}});

export const defDoneReducer = (st, act) =>
	update(st, {$merge: {wip: false, xhr: null, data: act.payload.data}});

const doneReducer = (st, act) =>
	update(st, {$merge: {wip: false, xhr: null}});

export const defFailReducer = (st, act) =>
	update(st, {$merge: {wip: false, xhr: null, err: act.payload.err}});

export const genDefDoneReducer = changeReducer => {
	if (changeReducer) {
		return reduceReducers(doneReducer, changeReducer);
	}
	return defDoneReducer;
};

const PO_START = 'start'
	, PO_STOP = 'stop'
	, PO_TOTAL = 'total'
	, PO_TIMER = 'timer'
	, defPeriodicReducer = (state, action) => {
		const { opr, value } = action.payload;
		switch (opr) {
			case PO_START:
				return update(state, {periodic: {$merge: {
					started: true,
					deferred: value,
					total: 0
				}}});
			case PO_STOP:
				return update(state, {periodic: {$merge: {
					started: false,
					timer: null,
					deferred: null
				}}});
			case PO_TOTAL:
				return update(state, {periodic: {total: {$set: value}}});
			case PO_TIMER:
				return update(state, {periodic: {timer: {$set: value}}});
			default:
				return state;
		}
	};

// createReduxCreators just bring close the default action and reducer handling
// AJAX. Future user should aware both action and reducer if modifying any of
// them.
const REDUX_ACTION_CREATOR = 0
	, REDUX_REDUCER_CREATOR = 1
	;
const createReduxCreators = which => {
	if (which === REDUX_ACTION_CREATOR) {
		return key => ({
			CANCEL: undefined,
			REQUEST: [undefined, () => ({key})],
			START: undefined,
			DONE: undefined,
			FAIL: [undefined, () => ({key})]
		});
	} else if (which === REDUX_REDUCER_CREATOR) {
		return changeDoneReducer => ({
			CANCEL: defCancelReducer,
			REQUEST: defRequestReducer,
			START: defStartReducer,
			DONE: genDefDoneReducer(changeDoneReducer),
			FAIL: defFailReducer
		});
	}
	throw 'invalid argument for createReduxCreators';
};

// reduceAsync is helper function to handle request multi-stages.
export const reduceAsync = (key, initState = {}, REQUEST, START, DONE, FAIL) => {
	const v = {REQUEST, START, DONE, FAIL};
	return handleActions({[key]: v}, initState);
};

const asyncMap = createReduxCreators(REDUX_ACTION_CREATOR);

const genAsyncReducerMap = createReduxCreators(REDUX_REDUCER_CREATOR);

// this is bit-masking
export const NO_MSK = 0
	, MSK_PERIODIC = 1 << 0
	, MSK_MULTI = 1 << 1
	;

const MAP_IDX_KEY = 0
	, MAP_IDX_ACTION = 1
	, MAP_IDX_REDUCER = 2
	;
const MSK_MAPS = {
		[MSK_PERIODIC]: [
			'PERIODIC'
			, key => undefined
			, defPeriodicReducer
		]
		, [MSK_MULTI]: [
			'MULTI'
			, key => undefined
			, undefined
		]
	};

const mapUpdater = (map, mask, whichMap, key) => {
	const m = MSK_MAPS[mask];
	let d = m[whichMap];
	if (whichMap === MAP_IDX_ACTION) {
		d = d(key);
	}
	return update(map, {$merge: {[m[MAP_IDX_KEY]]: d}});
};

function addMask(mask, array) {
	if (typeof mask === 'number') {
		if (mask & MSK_PERIODIC) {
			array.push(MSK_PERIODIC);
		}
		if (mask & MSK_MULTI) {
			// TODO: dummy now. Follow this pattern for future mask.
		}
	}
	return array;
}

const updatingReducerMap = (map, masks) =>
	masks.reduce((total, v) => mapUpdater(total, v, MAP_IDX_REDUCER), map);

const baseGenDefaultAsyncReducerMap = (changeDoneReducer, maskBits) => {
	let v = genAsyncReducerMap(changeDoneReducer);
	if (maskBits) {
		v = updatingReducerMap(v, addMask(maskBits, []));
	}
	return v;
};

const noMaskReducerMap = changeReducer =>
	baseGenDefaultAsyncReducerMap(changeReducer, NO_MSK);

// asyncStateReducer only update the async AJAX state without any data state
// manipulation nor data state update. Any user that requires the AJAX result
// data must explicitly handle the done action for the AJAX call. Useful for
// AJAX call within app state but update domain branch for common use.
export const asyncStateReducer = (key, startState = {}) =>
	handleActions({[key]: noMaskReducerMap(null)}, startState);

const baseDefaultAsyncReducer = (key, startState = {}, changeReducer, masks) => {
	const map = baseGenDefaultAsyncReducerMap(changeReducer, masks);
	return handleActions({[key]: map}, startState);
};

// defAsyncReducer is helper function to handle request multi-stages. Fetched
// data that don't require any user interaction like (select or update) but just
// display only can use this.
export const defAsyncReducer = (key, startState = {}, changeReducer) =>
	baseDefaultAsyncReducer(key, startState, changeReducer, NO_MSK);

export const defAsyncListReducer = (key, startState = {}, changeReducer) => {
	const v = {ALL: noMaskReducerMap(changeReducer)};
	return handleActions({[key]: v}, startState);
};

export const defAsyncOneReducer = (key, startState = {}, changeReducer) => {
	const v = {ONE: noMaskReducerMap(changeReducer)};
	return handleActions({[key]: v}, startState);
};

export const defAsyncSaveReducer = (key, startState = {}, changeReducer) => {
	const v = {SAVE: noMaskReducerMap(changeReducer)};
	return handleActions({[key]: v}, startState);
};

export const defAsyncCompleteReducer = (key, startState = {}) =>
	combineReducers({
		all: defAsyncListReducer(key, startState),
		one: defAsyncOneReducer(key, startState),
		save: defAsyncSaveReducer(key, startState)
	});

export const defCompleteAsyncReducerFromKeys = keys => {
	let combined = {};
	$.each(keys, function(i,v) {
		combined[v[1]] = defAsyncCompleteReducer(v[0], asyncInitState);
	});
	return combineReducers(combined);
};

const cancelAction = (actions, state) => (dispatch, getState) => {
	if(state.xhr) {
		state.xhr.abort();
	}
	dispatch(actions.cancel({time: Date.now()}));
};

const asyncBase = (cancel, localState, ajax, actions, param) => {
	let aborted;
	const o = (dispatch, getState) => {
		if (!cancel) {
			// console.log('dbg: multi-async triggered:', param);
		}
		let previousXhr;
		if (typeof localState === 'function') {
			previousXhr = localState(getState(), actions.stateName).xhr;
		} else if (typeof actions.localState === 'function') {
			previousXhr = actions.localState(getState()).xhr;
		}
		if (previousXhr) {
			previousXhr.abort();
			if (!aborted) {
				dispatch(actions.request({param, time: Date.now(), pure: true}));
			}
		} else if (!aborted) {
			dispatch(actions.request({param, time: Date.now()}));
		}
		if (aborted) {
			return Promise.reject(new Error("ajax aborted"));
		}
		let r = ajax()
			.done(data => {
				if (aborted) {
					return Promise.reject(new Error("ajax aborted when data arrive"));
				}
				dispatch(actions.done({data, param, time: Date.now()}));
			})
			.fail(err => {
				if(err.statusText === 'abort') {
					// don't dispatch anything when abort because Redux throw
					// out somehow if dispatch actions.fail here.
					return;
				} else if (aborted) {
					console.log("ajax aborted when error:", err);
					return;
				}
				dispatch(actions.fail({err, param, time: Date.now()}));
			});
		if (cancel) {
			dispatch(actions.start({xhr: r, param}));
		}
		return r;
	};
	o.setCancelState = state => aborted = !!state;
	return o;
};

// every AJAX call MUST go through this.
export const asyncCore = asyncBase;

const asyncThunk = (cancel, ajax, actions, param) => asyncCore(cancel,
	null, ajax, actions, param);

// async execute AJAX and will cancel any previous pending AJAX of the same
// action.
export const async = (
	ajax,
	actions,
	param
) => asyncThunk(true, ajax, actions, param);

// singletonAsync will always set the previous cancel state to true, so that its
// return data will not trigger any redux actions.
export const singletonAsync = () => {
	let previousAsyncSetCancelState = null;
	return (...args) => {
		if (typeof previousAsyncSetCancelState === "function") {
			previousAsyncSetCancelState(true);
			previousAsyncSetCancelState = null;
		}
		const _async = async(...args);
		previousAsyncSetCancelState = _async.setCancelState;
		return _async;
	}
};

// multiAsync like async but never cancel previous async action. This only make
// sense when param carries different data that expecting different value.
export const multiAsync = (ajax, actions, param) =>
	asyncThunk(false, ajax, actions, param);

export function cancellablePromise(func) {
	let canceler;
	const promise = new Promise((resolve, reject) => {
		func(resolve, reject);
		canceler = () => reject({ cancel: true });
	});
	return [promise, canceler];
}

const limitBeforeTrace = 10;

// cancel - cancel the repeating ajax calls (override delay parameter)
// delay  - repeated ajax calls are put into pending (delay until ongoing is done),
//			only the last pending calls will be triggered next
const makeCancellableAsync = (cancel, delay, actions, converter) => {
	let xhr = null
		, _resolve
		, _reject
		, _canceler = DummyFunction
		, nextAjax = null
		, nextParam
		, pending = 0
		;
	const reset = () => {
			xhr = null;
			nextAjax = null;
			nextParam = undefined;
			pending = 0;
		}
		, old = (ajax, param) => asyncThunk(
			cancel || delay
			, ajax
			, actions
			, param
		)
		, canceler = () => _canceler()
		, async = (ajax, param) => dispatch => {
			const [
					promise
					, canceler
				] = cancellablePromise((resolve, reject) => {
					_resolve = data => {
						if (typeof converter === "function") {
							data = converter(data);
						}
						dispatch(actions.done({data, param, time: Date.now()}));
						const _nextAjax = nextAjax
							, _nextParam = nextParam
							, _pending = pending
							;
						reset();
						if (_pending > 0) {
							data = dispatch(async(_nextAjax, _nextParam));
						}
						resolve(data);
					};
					_reject = err => {
						dispatch(actions.fail({err, param, time: Date.now()}));
						const _nextAjax = nextAjax
							, _nextParam = nextParam
							, _pending = pending
							;
						reset();
						if (_pending > 0) {
							err = dispatch(async(_nextAjax, _nextParam));
						}
						reject(err);
					};
				})
				;
			if (xhr) {
				if (cancel) {
					xhr.abort();
					_canceler();
					_canceler = canceler;
				} else {
					_canceler = canceler;
					if (delay) {
						if (pending < limitBeforeTrace) {
							pending++;
						} else {
							if (process.env.NODE_ENV !== 'production') {
								console.trace("too much requests!");
							}
						}
						nextAjax = ajax;
						nextParam = param;
						return promise;
					}
				}
			} else {
				_canceler = canceler;
			}
			dispatch(actions.request({param, time: Date.now(), pure: true}));
			xhr = ajax().done(data => _resolve(data)).fail(err => _reject(err));
			return promise;
		}
		;
	return [async, canceler, old];
};

// This is meant to solve multi ajax requests simulatenous issues where it
// cancels repeating request but keeping the latest request into pending list.
// Upon the ongoing ajax call is done, it proceed the pending request.
// Example of usage:
// const [
// 	errandListAsync
// 	, cancelErrandListAsync
// ] = makeDelayAsync(false, workflow[keyErrandList]);
//
// export const errandList = (context, q) => errandListAsync(
// 	postErrandList(q)
// 	, {context}
// );
export const makeDelayAsync = (old, actions, converter) => {
	const asyncs = makeCancellableAsync(false, true, actions, converter);
	if (old) {
		const [ _, cancel, oldAsync ] = asyncs;
		return [oldAsync, cancel];
	}
	return asyncs;
};

function promiseWithOptionalCancel(func, cancel) {
	if (cancel) {
		return cancellablePromise(func)
	}
	return [new Promise(func), dummy]
}

// Create 'broadcast' thunk that can be listen-ed by multiple caller with
// optional 'cancel'. 'cancel' option can be used to reject the promise and
// broadcast the rejection to all listeners. Or to cancel the underlaying AJAX
// request (by ignoring the completion result of the request) which can allow
// caller to dispatch another new request using the same promise that every
// other callers have been listening to. The later cancelation method hide the
// cancel request from other callers as if they never know any request has been
// cancel and renew its request.
export const broadcastWithOptionalCancel = (thunk, cancel) => {
	let p = null;
	let lastThunk = null;
	let promiseHandler = null;
	let _canceler = dummy;
	const createResolver = (_thunk, resolveOrReject) => data => {
		const { canceled } = _thunk;
		if (canceled && canceled.noReject) {
			return data;
		}
		// NOTE: clearing p MUST come before resolve / reject to make sure any
		// same request again will not get resolved promise because of p yet to
		// be cleared.
		p = null;
		resolveOrReject(data);
	};
	const createPromiseHandler = (resolve, reject) => (dispatch, ...args) => {
		lastThunk = thunk(...args);
		dispatch(lastThunk).
		then(createResolver(lastThunk, resolve)).
		catch(createResolver(lastThunk, reject));
	};
	const broadcaster = (...args) => dispatch => {
		if (p) {
			if (!lastThunk && typeof promiseHandler === "function") {
				promiseHandler(dispatch, ...args);
			}
			return p;
		}
		const [_p, _c] = promiseWithOptionalCancel((resolve, reject) => {
			promiseHandler = createPromiseHandler(resolve, reject);
			promiseHandler(dispatch, ...args);
		}, cancel);
		p = _p;
		_canceler = _c;
		return p;
	};
	if (cancel) {
		broadcaster.cancel = noReject => {
			if (lastThunk) {
				lastThunk.canceled = { noReject };
				if (typeof lastThunk.setCancelState === "function") {
					lastThunk.setCancelState(true);
				}
				lastThunk = null;
			}
			if (!noReject) {
				_canceler();
			}
		};
	} else {
		broadcaster.cancel = dummy;
	}
	return broadcaster;
};

// Create broadcast promise that any caller can be notified the on-going request
// have completed. Any request will not cancel previous request but wait for the
// broadcast promise. If there isn't any ongoing request then new request will
// be executed, and any future caller can listen on this request completion.
export const createBroadcaster = thunk => broadcastWithOptionalCancel(thunk);

const completeAsyncMap = key => ({
	ALL: asyncMap(key),
	ONE: asyncMap(key),
	SAVE: asyncMap(key)
});

// selectOprDataAsync is helper function that updates st.data.opr.select state.
// Standardizing store where data.opr store the operation states which is the
// user interaction state as selection (opr.select) in this case. payload.id is
// integer value that indicating the id of object that need to be updated with
// value payload.select. opr is stored as object where the key is integer of
// object of operation states.
export const selectOprDataAsync = (state, id, selected) => {
	let data = state.data;
	if(!data) {
		return state;
	}
	let opr = data.opr;
	const e = opr[id];
	if(!e) {
		return state;
	}
	const s = update(e, {$merge: {selected}});
	opr = update(opr, {$merge: {[id]: s}});
	data = update(data, {$merge: {opr}});
	return update(state, {$merge: {data}});
};

export const checkIfAllSelected = state => {
	let data = state.data;
	if(!data) {
		return state;
	}
	const opr = data.opr;
	let allSelected = true;
	$.each(opr, function(k,v) {
		if(!v.selected) {
			allSelected = false;
			return false;
		}
	});
	data = update(data, {$merge: {allSelected}});
	return update(state, {$merge: {data}});
};

export const checkIfAllSelectedCustomized = opr => {
	let allSelected = true;
	if ( Object.keys(opr).length > 0 ) {
		$.each(opr, function(k, v) {
			if (!v.selected) {
				allSelected = false;
				return false;
			}
		});
	} else {
		return false
	}
	return allSelected;
}

export const updateSelectAll = (state, select) => {
	let data = state.data;
	if(!data) {
		return state;
	}
	const prevOpr = data.opr;
	let opr = {};
	$.each(prevOpr, function(k,v) {
		if(select !== v.selected) {
			opr[k] = update(v, {$merge: {selected: select}});
		} else {
			opr[k] = v;
		}
	});
	data = update(data, {$merge: {allSelected: select, opr}});
	return update(state, {$merge: {data}});
};

const updatingActionMap = (map, masks, key) =>
	masks.reduce((total, v) => mapUpdater(total, v, MAP_IDX_ACTION, key), map);

export const selectAgentDataAsync = (state, id, selected) => {
	let agentList = state.agentCard.agentList;
	if(!agentList) {
		return state;
	}
	const e = agentList[id];
	if(!e) {
		return state;
	}
	const s = update(e, {$merge: {selected}});
	agentList = update(agentList, {$merge: {[id]: s}});
	return update(state, {agentCard: {$merge: {agentList}}});
};

export const selectGroupDataAsync = (state, id, selected) => {
	let groupList = state.groupCard.groupList;
	if(!groupList) {
		return state;
	}
	const e = groupList[id];
	if(!e) {
		return state;
	}
	const s = update(e, {$merge: {selected}});
	groupList = update(groupList, {$merge: {[id]: s}});
	return update(state, {groupCard: {$merge: {groupList}}});
};

export const selectIMDataAsync = (state, id, selected) => {
	let data = state.data;
	if(!data) {
		return state;
	}
	let opr = data.opr;
	const e = opr[id];
	if(!e) {
		return state;
	}
	const s = update(e, {$merge: {selected}});
	opr = update(opr, {$merge: {[id]: s}});
	data = update(data, {$merge: {opr}});
	return update(state, {$merge: {data}});
};

export const checkIfAllIMSelected = state => {
	let data = state.data;
	if(!data) {
		return state;
	}
	const opr = data.opr;
	let allSelected = false;
	if (Object.keys(opr).length !== 0) {
		allSelected = true;
		$.each(opr, function(k,v) {
			if(!v.selected) {
				allSelected = false;
				return false;
			}
		});
	}
	data = update(data, {$merge: {allSelected}});
	return update(state, {$merge: {data}});
};

export const updateSelectAllIM = (state, select) => {
	let data = state.data;
	if(!data) {
		return state;
	}
	const prevOpr= data.opr;
	let opr = {};
	$.each(prevOpr, function(k,v) {
		if(select !== v.selected) {
			opr[k] = update(v, {$merge: {selected: select}});
		} else {
			opr[k] = v;
		}
	});
	data = update(data, {$merge: {allSelected: select, opr}});
	return update(state, {$merge: {data}});
};

export const updateMsgReadState = (state, mid) => {
	let data = state.data;
	if(!data) {
		return state;
	}
	if (data.norm && (mid > 0)) {
		if(state.data.norm && state.data.norm[mid]) {
			return update(state, {data: {norm: {[mid]: {status :{$set: true}}}}});
		}
	}
	return state;
};

export const reduxCreators = (lists, localState) => {
	let res = {};
	$.each(lists, (i,v) => {
		let key, stateName, masks = [];
		if (typeof v === 'object' && v.length && v.length > 1) {
			key = v[0];
			if (v.length === 2) {
				const secondItem = v[1];
				if (typeof secondItem === 'number') {
					stateName = key;
					addMask(secondItem, masks);
				} else {
					stateName = secondItem;
				}
			} else {
				// more than 2 items
				stateName = v[1];
				const thirdItem = v[2];
				if (typeof thirdItem === 'number') {
					addMask(thirdItem, masks);
				}
			}
		} else {
			key = v;
			stateName = v;
		}
		const updatedMap = updatingActionMap(asyncMap(key), masks, key)
			, ac = createActions({[key]: updatedMap});
		$.each(ac, (k,w) => {
			w.stateName = stateName;
			if (typeof localState === 'function') {
				w.localState = store => localState(store)[stateName];
			}
			res[key] = w;
		});
	});
	return res;
};

export const createActionCreators = list => reduxCreators(list, null);

export const mapKeyToActionsAndStateName = (list, localState) =>
	reduxCreators(list, localState);

export const getStateName = map => map.stateName;

export const asyncRequestActionType = actionCreators => {
	return actionCreators.request().type;
};

export const asyncDoneActionType = actionCreators => {
	return actionCreators.done().type;
};

export const asyncFailActionType = actionCreators => {
	return actionCreators.fail().type;
};

export const createReducer = (init, handlers) => (st = init, act) => {
	if(handlers.hasOwnProperty(act.type)) {
		return handlers[act.type](st, act);
	} else {
		return st;
	}
};

const getMCAMChannelByTypeOrID = (field, value, mcamChannels) => {
	let found, content;
	$.each(mcamChannels, (i,v) => {
		if(v.content && v[field] === value) {
			found = true;
			content = JSON.parse(v.content);
			return false;
		}
	});
	if(found) {
		return content;
	}
};

const getMCAMChannelByID = (id, mcamChannels) => getMCAMChannelByTypeOrID('id', id, mcamChannels);

const getMCAMChannelByType = (id, mcamChannels) => getMCAMChannelByTypeOrID('type', id, mcamChannels);

export const mcamByID = (id, mcamData) => {
	if(!mcamData || !mcamData.mcam || !mcamData.mcam.channels) {
		return;
	}
	return getMCAMChannelByID(id, mcamData.mcam.channels);
};

export const mcamPromiseByID = (id, mcamData) => {
	if(!mcamData || !mcamData.mcam || !mcamData.mcam.channels) {
		return Promise.reject({err: new Error('invalid MCAM input')});
	}
	const mcam = getMCAMChannelByID(id, mcamData.mcam.channels);
	let err;
	if(!mcam) {
		err = new Error('can not parse MCAM data');
	} else if(mcam.error) {
		err = new Error(mcam.error);
	}
	if(err) {
		return Promise.reject({err});
	}
	return Promise.resolve(mcam);
};

export const mcamResult = mcamData => {
	if(!mcamData || !mcamData.mcam || !mcamData.mcam.channels) {
		return {error: new Error('invalid MCAM data')};
	}
	let result, error;
	const mcam = getMCAMChannelByType('Result', mcamData.mcam.channels);
	if(!mcam) {
		error = new Error('can not parse MCAM data');
	} else if(mcam.error) {
		error = new Error(mcam.error);
	} else {
		result = mcam;
	}
	return {result, error};
};

const parseCommentCounters = channels => {
	const myCount = getMCAMChannelByID('my-comment-count', channels),
		newCount = getMCAMChannelByID('new-comment-count', channels),
		reviewCount = getMCAMChannelByID('review-errand-count', channels),
		postponeCount = getMCAMChannelByID('postponed-errand-count', channels);
	let res = {};
	if(myCount) {
		res.my = myCount;
	}
	if(newCount) {
		res.new = newCount;
	}
	if(reviewCount) {
		res.review = reviewCount;
	}
	if(postponeCount) {
		res.postpone = postponeCount;
	}
	return res;
};

// mcamCountersReducer is helper function to get comment counter from MCAM AJAX.
export const mcamCountersReducer = (state, action) => {
	const { data } = action.payload;
	if(data && data.mcam && data.mcam.channels) {
		return update(state, {counters: {
			$merge: parseCommentCounters(data.mcam.channels)}});
	}
	return state;
};

export const removeNormalizedDomain = (state, key, id) => {
	const allIds = state[key].allIds;
	if(!allIds.length) {
		return state;
	}
	let found, index;
	$.each(allIds, (i,v) => {
		if(id === v) {
			found = true;
			index = i;
			return false;
		}
	});
	if(found) {
		const idstr = id.toString();
		return update(state, {[key]: {
			byId: {$unset: [idstr]},
			expiry: {$unset: [idstr]},
			allIds: {$splice: [[index, 1]]}
		}});
	}
	return state;
};

const addDomain = (state, key, id, object, time) => {
	let u = {
		byId: {$merge: {[id]: object}},
		expiry: {$merge: {[id]: time}}
	}, found, index;
	const allIds = state[key].allIds;
	if(allIds.length) {
		$.each(allIds, (i,v) => {
			if(id === v) {
				found = true;
				index = i;
				return false;
			}
		});
	}
	if(found) {
		// TODO: likely no need splice existing id.
		u.allIds = {$splice: [[index, 1, id]]};
	} else {
		u.allIds = {$push: [id]};
	}
	return update(state, {[key]: u});
};

export const addNormalizedDomain = (state, key, id, object, time) => {
	if (typeof time === 'undefined') {
		throw "must have time argument";
	}
	return addDomain(state, key, id, object, time);
};

// merge multiple items data at once into domain state branch.
export const mergeNormalizedDomainData = (state, key, data, time) => {
	const { allIds } = state[key]
		, expiry = {}
		, idExisted = []
		, notExistedId = []
		;
	$.each(allIds, id => {
		const d = data[id];
		if (typeof d !== "undefined") {
			idExisted.push(id);
			expiry[id] = time;
		}
	});
	const notExistData = update(data, {$unset: idExisted})
		;
	$.each(notExistData, id => {
		expiry[id] = time;
		notExistedId.push(id);
	});
	return update(state, {[key]: {
		byId: {$merge: data}
		, expiry: {$merge: expiry}
		, allIds: {$push: notExistedId}
	}});
};

// NOTE: not pure function. Can not be used in reducer. Can NOT be used inside
// selector too.
export const getValidDomainData = (domain, id) => {
	const expiry = domain.expiry[id];
	if(!expiry) {
		return;
	}
	if(Date.now() > expiry + domain.cacheTerm) {
		// expired
		return;
	}
	return domain.byId[id];
};

export const NEVER_EXPIRED_CACHE = -1, DO_NOT_CACHE = 0;

export const getNormalizedDomain = (state, key, id) => {
	const object = state[key];
	if(!object.byId || !object.expiry || object.cacheTerm === DO_NOT_CACHE) {
		return;
	} else if (object.cacheTerm === NEVER_EXPIRED_CACHE) {
		return object.byId[id];
	}
	return getValidDomainData(object, id);
};

export const mcamizer = (object, key) => {
	return {mcam: {channels: [{
		contents: JSON.stringify(object),
		id: key,
		type: 'Result'
	}]}};
};

export const getAppRoot = state => state.app;

export const getAppState = (store, key) => getAppRoot(store)[key];

export const getChatState = (store, key) => store.chat[key];

export const getChatSocket = state => getChatState(state, "socket");

class loadOnceTrack {
	constructor(height, width) {
		this.asyncs = {};
	}
	add(key, async) {
		this.asyncs[key] = async;
	}
	thunk() {
		const that = this;
		return dispatch => {
			let dispatches = [];
			$.each(that.asyncs, (k,v) => {
				dispatches.push(dispatch(v()));
			});
			return $.when(...dispatches);
		};
	}
}

export const loadOnceTracker = new loadOnceTrack();

const noReload = (dispatch, async, state, key, condition) => {
	const t = typeof condition;
	let force, wip;
	if (t === 'boolean') {
		force = condition;
	}
	const invalidData = state => {
		if (state.err) {
			return true;
		} else if (state.wip) {
			wip = true;
			return true;
		} else if (!state.data) {
			return true;
		}
	};
	// 'invalidData' must come last.
	if (force || (t === 'function' && condition(state)) || invalidData(state)) {
		loadOnceTracker.add(key, async);
		// only pass 'wip' with true when the data not ready solely just because
		// data is still being fetch, which is used to decide whether to cancel the
		// request. With broadcast, cancellation will not happen when data still
		// being fetch which can be overpower-ed by 'force' or conditional 'force'.
		if (wip) {
			if (process.env.NODE_ENV !== 'production') {
				console.trace("dbg: fixMe because same requests happen at same time");
			}
		}
		return dispatch(async(wip));
	}
	return $.when();
};

// noReloadThunk helps the async call only be called once by checking the
// current redux state of the call. This should only make sense if the AJAX call
// has no parameters and thus expecting same result everytime call. Don't
// overuse this function and it should be used for endpoint AJAX result that is
// quite constant over time. This helper mainly to allows AJAX to be dispatched
// without concern of over call the same the function multiple times and thus
// wasting bandwidth result. It is useful when different part of domains require
// a common AJAX call such as 'fetch workflow settings'.
const noReloadThunk = (async, localState, key, condition) =>
	(dispatch, getState) =>
		noReload(dispatch, async, localState(getState(), key), key, condition);

const optionYesIfIncludeInactive = includeInactive => {
	if (includeInactive) {
		return { option: "yes" };
	}
}

const includeInactiveObject = includeInactive => ({ includeInactive })

const noReloadWithOptionalInactiveAsync = (
	async,
	ajax,
	actions,
	ajaxParamCreator,
	stateParamCreator
) => (...args) => async(
	ajax(ajaxParamCreator(...args))
	, actions
	, stateParamCreator(...args)
);

const createNoReloadThunk = (
	async,
	ajax,
	actions,
	ajaxParamCreator,
	stateParamCreator,
	withBroadcaster
) => {
	let thunk = noReloadWithOptionalInactiveAsync(
		async,
		ajax,
		actions,
		ajaxParamCreator,
		stateParamCreator
	);
	if (withBroadcaster) {
		thunk = broadcastWithOptionalCancel(thunk, true);
	}
	return thunk;
};

export const noReloadCreator = (
	map,
	key,
	ajax,
	localStateGetter,
	ajaxParamCreator,
	stateParamCreator,
	withBroadcaster
) => {
	const thunk = createNoReloadThunk(
		async,
		ajax,
		map[key],
		ajaxParamCreator,
		stateParamCreator,
		withBroadcaster
	);
	return (...args) => {
		let force;
		if (args && args.length) {
			// last argument always for forcing reload.
			force = args[args.length-1];
		}
		const _thunk = wip => {
			// do not cancel when data in progress fetching. This can be manipulated
			// through the 'wip' which has lower precedence than 'condition' checker
			// that can be used to force reload.
			if (withBroadcaster && !wip) {
				thunk.cancel(true);
			}
			return thunk(...args);
		}
		return noReloadThunk(_thunk, localStateGetter, key, force);
	};
};

const dummy = doNothing;

export const loadOnceCreator = (map, key, ajax, localStateGetter) => force =>
	noReloadCreator(map, key, ajax, localStateGetter, dummy, dummy)(force);

const isIncludedInactive = ({ param }) => !param || !param.includeInactive;

const createForceConditionIncludeInactive = (
	includeInactive,
	force
) => state => force || (includeInactive && isIncludedInactive(state));

// Provide an optional inactive parameter when request AJAX. This only make
// senses when active list is subset of list that include inactive. With that,
// caller can make use of selector to sperate active and inactive list.
export const optInactiveNoReloadCreator = (
	map,
	key,
	ajax,
	localStateGetter,
	withBroadcaster
) => {
	const thunk = noReloadCreator(
		map,
		key,
		ajax,
		localStateGetter,
		optionYesIfIncludeInactive,
		includeInactiveObject,
		withBroadcaster
	);
	return (includeInactive, force) => thunk(
		includeInactive,
		createForceConditionIncludeInactive(includeInactive, force)
	);
};

const createMCAMDoneChangeReducer = mcamID => (state, action) => {
	let {data} = action.payload;
	data = mcamByID(mcamID, data);
	return update(state, {data: {$set: data}});
};

// defMCAMReducer like defAsyncReducer but auto parse MCAM and put it into data
// field.
export const defMCAMReducer = (key, startState = {}, mcamID) =>
	defAsyncReducer(key, startState, createMCAMDoneChangeReducer(mcamID));

export const getAreasIDViaOrgObj = (org, onlyActive) => {
	let ids = [];
	$.each(org, (i,v) => {
		if(v.Areas && (!onlyActive || v.Active)) {
			$.each(v.Areas, (j,w) => {
				if(!onlyActive || w.Active) {
					ids.push(w.Id);
				}
			});
		}
	});
	return ids.join(',');
};

// domainTransfer helper function to bridge data transfer from domain branch
// to individual app branch such as errand branch. NOTE: don't use this as using
// it mean anti-pattern as it likely the data duplicate between domain branch
// and individual app state branch. A proper way should be everything from
// domain branch. There is still big dilemma where to put 'dynamic app state'
// data, for example: checkboxes state for each errand within errand list, or
// the editable answer text that previously saved.
export const domainTransfer = (type, payload) => ({type, payload});

export const domainTransferReducer = stateBranch => (st, act) =>
	update(st, {[stateBranch]: {$merge: {domain: act.payload.domain}}});

export const getUniqueData = (data) => {
	var usedObjects = {};
	for (var i=data.length - 1;i>=0;i--) {
		var so = JSON.stringify(data[i]);
		if (usedObjects[so]) {
			data.splice(i, 1);
		} else {
			usedObjects[so] = true;
		}
	}
	return data;
};

export const externalExpertEditDomainID = (errand, query, answer) => errand + ':' + query + ':' + answer;

const createLimitKeeper = limit => {
	let _resolve = null
	let _count = 0
	let _promise = null
	const _finish = () => {
		_count--
		if (_promise) {
			const resolve = _resolve
			_promise = null
			_resolve = null
			if (typeof resolve === "function") {
				resolve()
			}
		}
	}
	const _check = () => {
		if (_count < limit) {
			_count++
			return Promise.resolve(_finish)
		}
		if (!_promise) {
			_promise = new Promise(resolve => _resolve = resolve)
		}
		return _promise.then(() => _check())
	}
	return _check
}

// Restrict concurrency execution of 'func' to 'limit' at any time.
export const createDispatchLimiter = (func, limit) => {
  const limiter = createLimitKeeper(limit)
  return (...args) => dispatch => limiter().then(finish => {
		return dispatch(func(...args)).then(data => {
			finish()
			return data
		}).catch(err => {
			finish()
			return Promise.reject(err)
		})
	})
}

// trigger periodic action of 'thunk' with timer 'timeout' and return an array
// in format [promise, function] where the promise will resolve when periodic
// timer stop and function that can be called to stop the timer. 'custom' can
// is 'function (result, error) [func nextThunk(), nextTimeout]' function which
// will be called with arguments previous dispatched 'thunk's result and error,
// and return an array carry the next thunk to be called by setTimeout and its
// timeout duration.
const rawPeriodicAsync = (state, thunk, timeout, custom, actions) =>
	dispatch => {
		const reload = (reloadFunc, result, error) => {
				if (state.timer) {
					clearTimeout(state.timer);
					state.timer = null;
				}
				if (!state.stop) {
					if (custom) {
						const [ nxtThunk, nxtTimeout ] = custom(result, error);
						timeout = nxtTimeout;
						thunk = nxtThunk;
					}
					state.timer = setTimeout(reloadFunc, timeout);
					if (actions) {
						dispatch(actions.periodic({
							opr: PO_TIMER
							, value: state.timer
						}));
					}
				} else {
					if (actions) {
						// requested stop but redux store still has 'started'
						// state true, so trigger stop clean up the state.
						dispatch(actions.periodic({opr: PO_STOP}));
					}
					state.deferred.resolve({totalSuccess: state.total});
				}
			}
			, func = () => dispatch(thunk)
				.then(data => {
					state.total++;
					if (actions) {
						dispatch(actions.periodic({
							opr: PO_TOTAL
							, value: state.total
						}));
					}
					reload(func, data);
				})
				.catch(err => {
					reload(func, null, err);
				})
			;
		if (!state.stop) {
			func();
			if (actions) {
				dispatch(actions.periodic({
					opr: PO_START
					, value: state.deferred
				}));
			}
		}
		return () => state.stop = true;
	};

const createLocalPeriodicState = () => ({total: 0, deferred: new $.Deferred()});

// TODO: there is bug where after stop and start again, the promise is not being
// created and will not work as it will always been resolved state.
export const periodicPoll = (thunk, timeout, custom, actions) => {
	let started;
	const state = createLocalPeriodicState()
		, start = () => dispatch => {
			if (typeof thunk.cancel === "function") {
				thunk.cancel(false);
			}
			if (!started) {
				started = true;
				state.stop = false;
				return dispatch(rawPeriodicAsync(
					state
					, thunk
					, timeout
					, custom
					, actions
				));
			} else {
				return state.deferred.promise();
			}
		}
		, stop = () => dispatch => {
			if (typeof thunk.cancel === "function") {
				thunk.cancel(true);
			}
			state.stop = true;
			if (!started) {
				return;
			}
			started = false;
			if (actions) {
				dispatch(actions.periodic({opr: PO_STOP}));
			}
			clearTimeout(state.timer);
			state.timer = null;
			state.deferred.resolve({totalSuccess: state.total});
		};
	return {
		start
		, stop
		, restart: () => dispatch => {
			dispatch(stop());
			return dispatch(start());
		}
	};
};

export const autoPolling = (autoThunkCreator, timeout, actions) => {
	const autoThunk = [ autoThunkCreator(true), timeout ];
	return periodicPoll(
		autoThunkCreator()
		, timeout
		, () => autoThunk
		, actions
	);
};

const defTimer = 250

// limit only one action dispatch per 'wait' miliseconds.
export function throttledDispatch(fn, wait) {
	let timeout;
	return dispatch => {
		let context = this
			, args = arguments
			;
		if (!timeout) {
			// the first time the event fires, we setup a timer, which
			// is used as a guard to block subsequent calls; once the
			// timer's handler fires, we reset it and create a new one
			timeout = setTimeout(() => {
				timeout = null;
				dispatch(fn.apply(context, args));
			}, wait || defTimer);
		}
	}
}

// NOTE: do not remove this as it can be useful for anyone need debounce feature
// with redux dispatch where any X dispatch actions within 'delay' timer will be
// combined into one action only.
// export function debounceDispatch(fn, delay) {
// 	let timeout;
// 	return dispatch => {
// 		const context = this
// 			, args = arguments
// 			;
// 		clearTimeout(timeout);
// 		timeout = setTimeout(() => {
// 			dispatch(fn.apply(context, args));
// 		}, delay || defTimer);
// 	};
// }

// thunk is redux-thunk function that will return promise and action is the
// redux action object.
export const triggerActionWhenFinish = (thunk, action) => dispatch =>
	dispatch(thunk).then(result => {
		dispatch(action);
		return result;
	});

export const sameContentIdInAttachments = (contentID, attachments) => {
	let found = false;
	$.each(attachments, (i, { contentId }) => {
		if (contentId && contentID === contentId) {
			found = true;
			return false;
		}
	});
	return found;
};

export const sameValidContentIdInAttachments = (contentID, attachments) => {
	if (!contentID) {
		return false;
	}
	return sameContentIdInAttachments(contentID, attachments);
};

const createMonoArgumentActionCreator = type => payload => ({type, payload});

const createSimpleReducer = updaterCreator => (state, { payload }) => update(
	state
	, updaterCreator(payload)
);

// helper to create an action creator and a reducer base on array.
export const actionReducerCreator = array => {
	let actions = {}
		, reducers = {}
		;
	$.each(array, (i, [ key, updaterCreator ]) => {
		actions[key] = createMonoArgumentActionCreator(key);
		reducers[key] = createSimpleReducer(updaterCreator);
	});
	return [actions, reducers];
};

export const createSetPayloadReducer = field => createSimpleReducer(value => ({[field]: {$set: value}}));

// return action creator that take in mono argument as action object 'payload'
// field value.
export const createDirectActionCreator = type => payload => ({type, payload});

// helper create action and reducer for ticker by returning an array contains:
// index 0: initial state that is needed to put inside reducer.
// index 1: redux action creator to start the ticker count.
// index 2: redux action creator to stop the ticker manually before it stop
//          itself.
// index 3: redux reducer that need to be placed on reducers for this helper to
//          work.
// 'actionType': redux action type constant prefix.
// 'selector': function that take root state and return this helper state.
// 'tick': how many ticks before stop. Duration depends on 'timer'.
// 'timer': duration in ms for each tick interval.
export const tickerTimerActionReducerCreator = (
	actionType
	, selector
	, tick = 5
	, timer = 1000
) => {
	let t, tickerEndActionFunc;
	const initState = {tick: 0, stop: true}
		, stopActionType = actionType + "/stop"
		, updateActionCreator = payload => ({type: actionType, payload})
		, stopActionCreator = payload => ({type: stopActionType, payload})
		, stopInterval = () => {
			if (t) {
				clearInterval(t);
				t = null;
			}
		}
		, stop = triggerEndAction => dispatch => {
			stopInterval();
			dispatch(stopActionCreator(true));
			if (triggerEndAction && typeof tickerEndActionFunc === "function") {
				dispatch(tickerEndActionFunc());
			}
			if (tickerEndActionFunc) {
				tickerEndActionFunc = null; // only trigger once
			}
		}
		;
	return [
		initState
		, func => (dispatch, getState) => {
			tickerEndActionFunc = func;
			stopInterval();
			dispatch(stopActionCreator(false));
			dispatch(updateActionCreator(tick));
			t = setInterval(
				() => {
					let { tick } = selector(getState());
					tick--;
					dispatch(updateActionCreator(tick));
					if (!tick) {
						dispatch(stop(true));
					}
				}
				, timer
			);
		}
		, stop
		, (state = initState, { type, payload }) => {
			if (type === actionType) {
				return update(state, {tick: {$set: payload}});
			} else if (type === stopActionType) {
				return update(state, {stop: {$set: payload}});
			}
			return state;
		}
	];
};

export const createReducerSubBranch = (
	updater
	, selector
	, reducer
) => (state, action) => {
	const sub = selector(state)
		, result = reducer(sub, action)
		;
	if (sub !== result) {
		return updater(state, result);
	}
	return state;
};

// reducer that only update 'async.data' branch.
export const createReducerAsyncDataBranch = reducer => createReducerSubBranch(
	(state, value) => update(state, { data: { $set: value } }),
	state => state.data,
	reducer
)

export const createRootUpdater = branch => (state, updater) => update(
	state,
	{ app: { [branch]: updater } }
);

export const isMobile = false;

const createNormalizedRemoverUpdater = (
	byIdName,
	allIdsName
) => (id, array) => {
	if (!array || !array[allIdsName]) {
		console.log('error invalid array data')
		return
	}
	const index = indexOf(array[allIdsName], id)
	if (index < 0) {
		console.log('error can not find id:', id)
		return
	}
	return {
		[byIdName]: { $unset: [id] },
		[allIdsName]: { $splice: [[index, 1]] }
	}
}

export const normalizeRemoverUpdater = createNormalizedRemoverUpdater(
	'byId',
	'allIds'
)

// cancellableAsync, when dispatch, create a thunk that return promise with
// field 'cancel' function that can be used to cancel on-going async.
export const cancellableAsync = (async, key, param) => dispatch => {
	let cancel
	const _async = multiAsync(async, key, param)
	const promise = dispatch(_async)
	promise.cancel = _async.setCancelState
	return promise
}

export const sortByCol = (array, column, sortDir) => {
	var dataCheck = "";
	var dataType = "string";
	if (array != undefined && array.length > 0) {
		dataCheck = array[0][column];
		if (dataCheck === parseInt(dataCheck, 10)) {
			dataType = "int";
		}
		return array.sort(function (a, b) {
			var x = a[column];
			var y = b[column];
			if (sortDir === 'asc') {
				if (dataType == "string") {
					return x.toString().toLowerCase().localeCompare(y.toString().toLowerCase(), undefined, {
						numeric: true
					});
				} else {
					return x - y;
				}
			} else {
				if (dataType == "string") {
					return y.toString().toLowerCase().localeCompare(x.toString().toLowerCase(), undefined, {
						numeric: true
					});
				} else {
					return y - x;
				}
			}
		});
	}
}
